import * as React from "react"
import SiteLayout from "../components/SiteLayout";
import {Post} from "../components/Post";
import {graphql} from "gatsby";

// markup
const IndexPage = ({data, tag}) => {
  const posts = data.allMdx.edges;

  return (
      <SiteLayout>
          <h2 className={`text-2xl pb-4`}>Posts</h2>

          <>
              {posts.map((post) => (
                  <Post key={post.node.frontmatter.slug} post={post.node}/>
              ))}
          </>
      </SiteLayout>
  )
}

export const postsQuery = graphql`
query BlogPosts {
  allMdx(sort: {order: DESC, fields: frontmatter___date}) {
    edges {
      node {
        excerpt
        frontmatter {
          title,
          slug,
          date
        }
      }
    }
  }
}
`

export default IndexPage
